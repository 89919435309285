<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      text
      outlined
      :ripple="false"
      height="48"
      min-width="54"
      class="rounded refresh-btn"
    >
      <v-icon :color="hover ? 'inputColorHover' : 'inputColor'">${{icon}}</v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'IconButton',
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      required: true
    },
  },
}
</script>
