<template>
  <v-hover #default="{ hover }">
    <v-textarea
      ref="textareaRef"
      class="text-input-field"
      v-model="fieldValue"
      v-bind="$attrs"
      v-on="$listeners"
      flat
      solo
      :background-color="focusField || hover || !!value ? backGround : ''"
      @focus="changeFocus(true)"
      @blur="changeFocus(false)"
    >
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-textarea>
  </v-hover>
</template>

<script>
export default {
  name: 'FormTextarea',
  inheritAttrs: false,
  props: {
    value: {
      type: String | Number,
      default: ''
    },
    backGround: {
      type: String,
      default: 'inputFocus'
    },
  },
  data: () => ({
    focusField: false
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    changeFocus(val) {
      this.focusField = val
    }
  }
}
</script>

<style lang="scss">
.v-input.v-text-field.text-input-field {
  & > .v-input__control {
    & > .v-input__slot {
      padding: 0 22px!important;
      
      & > .v-text-field__slot {
        & > textarea {
          margin: 15px 0;
          font-weight: 500!important;
          font-size: 14px!important;
          line-height: 17px!important;
          letter-spacing: 0.008em!important;
        }
      }
    }
  }
}
</style>
